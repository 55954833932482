import React, { useEffect, useState } from "react";
import { Button } from "../../../../../components/Button/Button";
import { Input } from "../../../../../components/Input/Input";
import { Select } from "../../../../../components/Select/Select";
import { CapsuleField, CardForm } from "./styledFormContact";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FooterForm } from "../../../../Home/formHome/styledFormHome";
import InputMask from "react-input-mask";
import axios from "axios";
import { Loading } from "../../../../../components/Loading/Loading";
import { sendRequest } from "../../../../../services/sendRequest";

export const FormContact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [result, setResult] = useState([]);
  const [sendingEmail, setSendingEmail] = useState(false);

  const portalId = "8759286";
  const formId = "c07b00c4-7e74-46d8-87e5-7309d35fc680";

  const submitHubspotForm = async (data) => {
    const fields = Object.keys(data)
      .filter((key) => key !== "acceptTerms")
      .map((key) => ({
        name: key,
        value: data[key],
      }));

    try {
      setSendingEmail(true);

      const response = await sendRequest.sendEmail(portalId, formId, {
        fields,
      });

      const { status } = response;

      if (status === 200) {
        toast.success("Obrigado por enviar o formulário.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSendingEmail(false);
    } catch (error) {
      setSendingEmail(false);

      if (error) {
        toast.error("Erro ao enviar o formulário, tente novamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `https://qsqd2pawhwgim2on6dsn2gfgv40fxkqj.lambda-url.us-east-1.on.aws`
        );
        if (result.status === 200) {
          setResult(result.data);
          return result;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    };
    return fetchData();
  }, []);

  const identifierForm = result?.findIndex(({ guid }) => {
    return guid === formId;
  });

  const labelFields = result[identifierForm]?.formFieldGroups?.map(
    ({ fields }) => fields
  );
  const verifyLabel = labelFields === undefined ? [] : labelFields;

  const concatLabels = [].concat(
    verifyLabel[0],
    verifyLabel[1],
    verifyLabel[2],
    verifyLabel[3]
  );

  const flattenedArray = verifyLabel
    .filter((fields) => !fields[0]?.hidden)
    .reduce((accumulator, currentArray) => {
      return accumulator.concat(currentArray);
    }, []);

  const getInputs = (labelFields) => {
    return labelFields?.map(
      ({
        name,
        label,
        placeholder,
        type,
        required,
        options,
        unselectedLabel,
      }) => {
        if (type !== "enumeration") {
          return (
            <CapsuleField key={name}>
              <label htmlFor={name}>
                {label}
                {required && " *"}
              </label>
              <Input
                {...register(name, { required })}
                name={name}
                id={name}
                type="text"
                placeholder={placeholder}
              />
              {errors?.firstname?.type === "required" && (
                <p>Preencha esse campo obrigatório</p>
              )}
            </CapsuleField>
          );
        }

        if (name === "phone") {
          return (
            <CapsuleField key={name}>
              <label htmlFor={name}>
                {label}
                {required && " *"}
              </label>
              <InputMask
                mask="(99)99999-9999"
                {...register(name, { required })}
                type="text"
                name={name}
                id={name}
                placeholder={placeholder}
              />
              {errors[name]?.type === "required" && (
                <p>Preencha esse campo obrigatório</p>
              )}
            </CapsuleField>
          );
        }

        if (type === "enumeration") {
          return (
            <CapsuleField key={name}>
              <label htmlFor={name}>
                {label}
                {required && " *"}
              </label>
              <Select
                id={name}
                {...register(name, {
                  required,
                })}
              >
                <option selected value="">
                  {unselectedLabel}
                </option>
                {options.map(({ label, value }) => {
                  return (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Select>
              {errors[name]?.type === "required" && (
                <p>Preencha esse campo obrigatório</p>
              )}
            </CapsuleField>
          );
        }
        return [];
      }
    );
  };

  const onSubmit = handleSubmit((data) => {
    const hubspot_response = submitHubspotForm(data);

    return hubspot_response;
  });

  return (
    <CardForm onSubmit={onSubmit}>
      <Loading loading={concatLabels[0] === undefined} />
      {getInputs(flattenedArray)}

      <FooterForm>
        <input
          type="checkbox"
          {...register("acceptTerms", { required: true })}
          id="acceptTerms"
        />
        <label htmlFor="acceptTerms">
          {" "}
          Eu concordo em receber outras comunicações da Cinnecta. *
        </label>
        {errors?.acceptTerms?.type === "required" && (
          <p>Aceite os termos de privacidade.</p>
        )}
        <span>
          Ao informar meus dados, concordo com a{" "}
          <a
            href="https://cinnecta.com/privacidade/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Política de Privacidade.
          </a>{" "}
          Você pode alterar suas permissões de comunicação a qualquer tempo.
        </span>
      </FooterForm>
      <Button variant="primary" label="enviar" disabled={sendingEmail} />
      <Loading loading={sendingEmail} />
    </CardForm>
  );
};
