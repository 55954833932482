import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { useContact } from "../../hooks/methods/contact";
import Layout from "../../templates/MainTemplate";
import { ContactUs } from "../../view/contact/contactUs/contactUs";
import { DepositionsContact } from "../../view/contact/depositionContact";
import { LocationContact } from "../../view/contact/locationContact";
import { CompaniesView } from "../../view/Home/companies";

export default function Contact() {
  const { data, isSuccess } = useContact();

  const {
    descriptionContact,
    depositionContact,
    ourAddress,
    leadingCompany,
  } = isSuccess && data?.contactPage;
  return (
    <Layout>
      <ContactUs descriptionData={isSuccess && descriptionContact} />
      <CompaniesView data={isSuccess && leadingCompany} />
      <DepositionsContact data={isSuccess && depositionContact} />
      <LocationContact data={ourAddress} />
    </Layout>
  );
}

export const Head = ({
  data: {
    cmsContactPage: 
      {seo: { title: seoTitle, description: seoDescription, keywords, image: {url} }},
  },
}) => {
  const keywordsList = keywords.toString()
  return (
    <Seo title={seoTitle} description={seoDescription} keywords={keywordsList} url={url} />
  );
};

export const query = graphql`
  query PageQuery {
    cmsContactPage {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;