import React from "react";
import { Title } from "../../../components/Title/Title";
import { ContainerCompanies, WrapperImages } from "./styledCompanies";


export const CompaniesView = ({data}) => {
  const { titleLeadingCompany, imageLeadingCompany } = data;
 
  return (
    <ContainerCompanies>
      <Title label={titleLeadingCompany} />
      <WrapperImages>
        {imageLeadingCompany?.map(({ url, id, textAlt }) => (
          <img key={id} src={url} alt={textAlt} loading="lazy" />
        ))}
      </WrapperImages>
    </ContainerCompanies>
  );
};
